import { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  LinearProgress,
  CircularProgress,
  Box,
  Fade
} from "@mui/material";

import { useStore, observer } from "../../service/mobx";
import ToolTip from "../Tooltip";
import Skeleton from "../Skeleton";

function Plan() {
  const [usage, setUsage] = useState([]);
  const { analytics, layout, user } = useStore();
  const { credits, table, units, premium, daysLeft } = analytics.meters;
  const loaded = user.loaded && (user.syncedWithStripe || user.isAnonymous);

  useEffect(() => {
    if (premium) {
      const usage = [];
      const colors = {
        micro: ["var(--primary-color)", 0.5],
        ["micro-lm"]: ["var(--primary-color)", 0.5],
        xs: ["var(--primary-color)", 0.25],
        ["xs-lm"]: ["var(--primary-color)", 0.25],
        sm: ["var(--primary-color)", 0],
        ["sm-lm"]: ["var(--primary-color)", 0],
        md: ["var(--tertiary-color)", 0.5],
        ["md-lm"]: ["var(--tertiary-color)", 0.5],
        lg: ["var(--tertiary-color)", 0.25],
        ["lg-lm"]: ["var(--tertiary-color)", 0.25],
        xl: ["var(--tertiary-color)", 0.0],
        ["xl-lm"]: ["var(--tertiary-color)", 0.0],
        xxl: ["var(--secondary-color)", 0.5],
        ["xxl-lm"]: ["var(--secondary-color)", 0.5],
        super: ["var(--secondary-color)", 0],
        ["super-lm"]: ["var(--secondary-color)", 0]
      };
      for (const row of table) {
        const [bgcolor, opacity] = colors[row.meter];

        usage.push({ ...row, bgcolor, opacity: 1 - opacity });
      }

      setUsage(usage);

      return () => setUsage([]);
    }
  }, [premium, table]);

  return (
    <Stack
      px={1}
      spacing={1}
      height={36}
      alignSelf="center"
      justifyItems="center"
      alignItems={layout.navOpen ? undefined : "center"}
      sx={{ whiteSpace: "nowrap", width: "100%" }}
    >
      {layout.navOpen ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            {loaded ? (
              <Typography variant="labelMd" color="var(--mui-secondary-color)">
                {user.isAnonymous
                  ? "Model API"
                  : premium
                  ? "Premium"
                  : "Free tier"}
              </Typography>
            ) : (
              <Skeleton
                width={48}
                height={16}
                borderRadius="var(--shape-xs-round)"
              />
            )}
            {loaded ? (
              premium ? null : (
                <Typography
                  variant="labelMd"
                  color="var(--mui-secondary-color)"
                >
                  Free credits
                </Typography>
              )
            ) : null}
          </Stack>
          {loaded ? (
            premium ? (
              <ToolTip
                placement={units ? "right" : "top"}
                title={units ? units : "No API usage this period"}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: 12,
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "var(--shape-round)",
                    bgcolor: "var(--surface-container-highest)"
                  }}
                >
                  {usage.map(
                    ({ meter, price, quantity, total, bgcolor, opacity }) => (
                      <ToolTip
                        key={meter}
                        placement="top"
                        sx={{ flex: total, bgcolor, opacity }}
                        title={`${meter} models: ${quantity} sec x $${price} per sec = $${total}`}
                      />
                    )
                  )}
                </Box>
              </ToolTip>
            ) : (
              <ToolTip
                placement="top"
                title={
                  user.isAnonymous
                    ? "Run models for free by logging"
                    : `${parseInt(
                        credits
                      )} free credits. Credits refresh in ${daysLeft} days`
                }
              >
                <LinearProgress
                  variant="determinate"
                  value={user.isAnonymous ? 100 : credits}
                  sx={{ height: 12, borderRadius: "var(--shape-round)" }}
                />
              </ToolTip>
            )
          ) : (
            <Skeleton width="100%" height={12} />
          )}
        </>
      ) : (
        <Stack justifyContent="flex-end" sx={{ height: "inherit" }}>
          {loaded ? (
            <Fade
              in
              appear
              timeout={100}
              sx={{ transitionDelay: "200ms !important" }}
            >
              <ToolTip
                placement="right"
                title={
                  premium
                    ? units
                      ? "Usage this period"
                      : "No API usage this period"
                    : "Credits"
                }
              >
                <CircularProgress
                  size={24}
                  variant="determinate"
                  value={(premium ? units : credits) || 100}
                />
              </ToolTip>
            </Fade>
          ) : (
            <Box mb={1}>
              <Skeleton borderRadius="50%" height={24} width={24} />
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default observer(Plan);
